<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
    <v-dialog v-model="showRemove" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">Delete Best Practice?</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap> Are you sure you would like to delete this best practice? </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue en-1" text @click="closeRemove()"> Cancel </v-btn>
          <v-btn color="red en-1" text @click="remove()"> Delete </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
  import { mapActions } from "vuex"
  import { mapFields } from "vuex-map-fields"
  export default {
    name: "BestpracticeDeleteDialog",
    data() {
      return {}
    },
    computed: {
      ...mapFields("bestpractice", ["dialogs.showRemove"]),
    },

    methods: {
      ...mapActions("bestpractice", ["remove", "closeRemove"]),
    },
  }
</script>
