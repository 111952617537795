<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
    <v-container fluid>
      <new-edit-sheet />
      <delete-dialog />
      <v-row align="center" justify="space-between" no-gutters>
        <v-col cols="8">
          <settings-breadcrumbs v-model="project" />
        </v-col>
        <v-col class="text-right">
          <v-btn color="info" class="mr-2" @click="createEditShow">
            <v-icon class="mr-1">mdi-plus</v-icon>
            New Best Practice
          </v-btn>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-card elevation="0">
            <v-card-title>
              <v-text-field
                v-model="q"
                append-icon="search"
                label="Search"
                single-line
                hide-details
                clearable
              />
            </v-card-title>
            <v-row>
              <v-col
                v-for="item in items"
                :key="item.id"
                cols="12"
                md="6"
                lg="4"
              >
                <v-card class="ma-5" outlined>
                  <v-card-title class="d-flex justify-space-between align-center">
                    <div>
                      {{ item.name }}
                    </div>
                  </v-card-title>
                  <v-card-subtitle class="mt-2">
                    <div>
                      <v-icon small>mdi-axis-arrow-info</v-icon>
                      {{ item.description }}
                    </div>
                    <div v-if="item.url">
                      <v-icon small>mdi-link-variant</v-icon>
                      <a :href="item.url" target="_blank">{{ item.url }}</a>
                    </div>
                    <div v-if="item.category">
                      <v-icon small>mdi-tag-outline</v-icon>
                      {{ item.category }}
                    </div>
                  </v-card-subtitle>
                  <v-card-text>
                    <a :href="item.url" target="_blank">{{ item.url }}</a>
                  </v-card-text>
                  <!-- Actions -->
                  <v-card-actions>
                  <!--Item Count in a-->
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip
                          v-if="item.count > 10"
                          label
                          v-bind="attrs"
                          v-on="on"
                          class="mr-3"
                        >
                          <v-icon left x-small>mdi-domain</v-icon>
                          {{ item.count }}
                        </v-chip>
                        <v-chip
                          v-else
                          label
                          v-bind="attrs"
                          v-on="on"
                          class="mr-3"
                        >
                          <v-icon left x-small>mdi-domain</v-icon>
                          {{ item.count }}
                        </v-chip>
                        </template>
                        <span>{{ item.count }} times mentioned</span>
                    </v-tooltip>

                    <v-spacer />
                    <v-menu bottom left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="createEditShow(item)">
                          <v-list-item-title>Edit</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="removeShow(item)">
                          <v-list-item-title>Delete</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>

            <v-data-table
              :headers="headers"
              :items="items"
              :server-items-length="total"
              :page.sync="page"
              :items-per-page.sync="itemsPerPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="descending"
              :loading="loading"
              loading-text="Loading... Please wait"
            >
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>

  <script>
  import { mapFields } from "vuex-map-fields"
  import { mapActions } from "vuex"
  import RouterUtils from "@/router/utils"

  import SettingsBreadcrumbs from "@/components/SettingsBreadcrumbs.vue"
  import DeleteDialog from "@/data/bestpractice/DeleteDialog.vue"
  import NewEditSheet from "@/data/bestpractice/NewEditSheet.vue"

  export default {
    name: "BestPracticeTable",
    components: {
      DeleteDialog,
      NewEditSheet,
      SettingsBreadcrumbs,
    },
    data() {
      return {
        headers: [],
      }
    },
    computed: {
      ...mapFields("bestpractice", [
        "table.options.q",
        "table.options.page",
        "table.options.itemsPerPage",
        "table.options.sortBy",
        "table.options.descending",
        "table.options.filters.project",
        "table.loading",
        "table.rows.items",
        "table.rows.total",
      ]),
      ...mapFields("route", ["query"]),
      defaultUserProjects: {
      get() {
        let d = null
        if (this.projects) {
          let d = this.projects.filter((v) => v.default === true)
          return d.map((v) => v.project)
        }
        return d
      },
    },
    },
    created() {
      this.filters = {
        ...this.filters,
        ...RouterUtils.deserializeFilters(this.query),
        project: this.defaultUserProjects,
      }

      if (this.query.project) {
        this.project = [{ name: this.query.project }]
      }

      this.getAll()
      this.$watch(
        (vm) => [vm.page],
        () => {
          this.getAll()
        }
      )

      this.$watch(
        (vm) => [vm.q, vm.itemsPerPage, vm.sortBy, vm.descending, vm.project],
        () => {
          this.page = 1
          // if this.project not set, then set it to default project
          if (!this.project) {
            this.project = [{ name: this.defaultUserProjects[0] }]
          }
          this.$router.push({ query: { project: this.project[0].name } })
          this.getAll()
        }
      )
    },

    methods: {
      ...mapActions("bestpractice", ["getAll", "createEditShow", "removeShow"]),
    },
  }
  </script>
